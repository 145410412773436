import React from 'react'
import Board from './Board';
import Founder from './Founder';

import Hero from './Hero';
import Islam from './Islam';
import Projects from './Projects';
import Service from './Service';
import About from './About';
import CService from './Cservice';
import Volunteering from './Vol';
import Membership from './Membership';
import Donate from './Donate';
import Contact from './Contact';

function Home() {
  return (
    <div>
        <Hero/>
        <About/>
     
      
        <Projects/>
       
       
      
        
        <Contact/>
 
        </div>
  )
}

export default Home